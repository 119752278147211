export const MERCHANT_QUOTES = [
	"People always want magic weapons. I just want a nap.",
	"I heard there's a dragon out there somewhere.",
	"What you need a shield for? Just don’t get in fights, that’s what I do.",
	"You want a map? Here’s a thought—try just remembering stuff.",
	"Some say the forest creatures are weak to fire.",
	"Greetings to you.",
	"Need a weapon to save the kingdom? I’d rather just move.",
	"What can I do for you?",
	"Only the finest goods here.",
	"Shop around... Come back... It's up to you.",
	"You want armor? Why don’t you just try not getting hit? Seems easier.",
	"You know what’s underrated? Not going on quests.",
	"How can I help?",
	"You want armor? Just wear a thick jumper. More comfortable.",
	"A quest for glory? I’ll pass.",
	"Everyone wants to save the kingdom. Have you looked around? It’s a dump!",
	"Take a look around.",
	"Dragons, goblins, ancient evils... just another thing to whinge about.",
	"Hail Traveller.",
	"People going on quests… I’d just get lost.",
];

export const POTION_SELLER_QUOTES = [
	"I don’t just sell potions. I sell results.",
	"It’s not magic, it’s science... with a dash of mystery.",
	"I’m not just a potion seller, I’m a healer... and a businessman.",
	"Welcome to my humble shop.",
	"I could charge more, but I’m a man of the people.",
	"I mean, I don’t have to explain how potions work. You drink, you feel better.",
	"How are you today?",
	"People think it's all about magic, but it’s about quality.",
	"If only there was a way to carry more potions. Oh well.",
	"My potions speak for themselves. Well, they would if they could.",
	"Hello friend.",
	"Some say dead heroes are coming back to life.",
	"My potions don’t just heal, they change lives...",
	"I’m not just a potion seller. I’m a friend. A friend who sells potions.",
	"They say money can’t buy happiness, but it can buy my potions.",
	"What can I help you with?",
	"You want potions? I got potions.",
	"Potions for sale!",
	"It’s not rocket science. It’s potion science.",
	"I could sell you potions all day. And I do.",
];
